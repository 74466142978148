import React from "react";
import { ReactPhotoSphereViewer } from "react-photo-sphere-viewer";

interface PanoramaProps {
    src: string
}

export default function Panorama(props: PanoramaProps) {
    const { src } = props
    return (
        <ReactPhotoSphereViewer
            src={src}
            height={"40vh"}
            width={"100%"}
        />
    )
}
