import { Grid } from '@material-ui/core';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import useCollectionCategory from '../../hooks/useCollectionCategory';
import { useGtXs } from '../../hooks/useGtXs';
import ActionButton from '../ActionButton/ActionButton';
import CollectionCategories from '../CollectionCategories/CollectionCategories';
import Hero from '../Hero/Hero';
import Offering from '../Offering/Offering';
import Paragraph from '../Paragraph/Paragraph';
import styles from './CollectionCategoryView.module.scss';

interface CollectionCategoryViewProps extends RouteComponentProps<{}> { }

const Content = (props: any) => {
    const { description } = props

    const gtXs = useGtXs()

    const variant = gtXs ? "RocGroteskMedium25" : "RocGroteskMedium20"

    return (
        <div className={styles.Description}>
            <Paragraph variant={variant} style={{ marginBottom: 20, lineHeight: 1.2 }}>
                {description}
            </Paragraph>
        </div>
    )
}



export default function CollectionCategoryView(props: CollectionCategoryViewProps) {

    const { match } = props
    const collectionCategory = useCollectionCategory(match.path)
    const gtXs = useGtXs()
    const { id, action: { name, url, action }, description, offerings } = collectionCategory

    return (
        <div className={styles.Wrapper}>
            <Hero images={collectionCategory.hero} />
            <Grid container justify="center" alignItems="flex-start">
                <main>
                    <Grid container spacing={gtXs ? 10 : 0}>
                        <Grid item md={5} xs={12}>
                            <ActionButton name={name} action={action} url={url} fullWidth={gtXs ? false : true} />
                        </Grid>

                        <Grid item md={7} xs={12}>
                            <Content description={description} />
                        </Grid>
                    </Grid>

                    <div>
                        {offerings?.map(offering => <Offering key={offering.id} offering={offering} />)}
                    </div>

                    <Grid container justify="center" className={styles.BackButton}>
                        <ActionButton name="Voltar Coliseu Collection" url="/o-coliseu/collection" fullWidth={gtXs ? false : true} />
                    </Grid>

                    <CollectionCategories hideItemId={id} justify="center" itemsPerRow={gtXs ? 4 : 2} />

                </main>
            </Grid>
        </div>
    )
}
