import performances_desktop_hero from '../assets/images/o_coliseu/collection/performances/hero_desktop.webp'
import performances_mobile_hero from '../assets/images/o_coliseu/collection/performances/hero_mobile.webp'
import performances_thumbnail from '../assets/images/o_coliseu/collection/performances/thumbnail.webp'
import eu_verdadeiro_1 from '../assets/images/o_coliseu/collection/performances/eu_verdadeiro/eu_verdadeiro_1.jpg'
import eu_verdadeiro_2 from '../assets/images/o_coliseu/collection/performances/eu_verdadeiro/eu_verdadeiro_2.jpg'
import eu_verdadeiro_3 from '../assets/images/o_coliseu/collection/performances/eu_verdadeiro/eu_verdadeiro_3.jpg'
import eu_verdadeiro_4 from '../assets/images/o_coliseu/collection/performances/eu_verdadeiro/eu_verdadeiro_4.jpg'
import eu_verdadeiro_5 from '../assets/images/o_coliseu/collection/performances/eu_verdadeiro/eu_verdadeiro_5.jpg'
import eu_verdadeiro_6 from '../assets/images/o_coliseu/collection/performances/eu_verdadeiro/eu_verdadeiro_6.jpg'
import eu_verdadeiro_7 from '../assets/images/o_coliseu/collection/performances/eu_verdadeiro/eu_verdadeiro_7.jpg'
import eu_verdadeiro_8 from '../assets/images/o_coliseu/collection/performances/eu_verdadeiro/eu_verdadeiro_8.jpg'
import eu_verdadeiro_9 from '../assets/images/o_coliseu/collection/performances/eu_verdadeiro/eu_verdadeiro_9.jpg'
import pequeno_prazer_1 from '../assets/images/o_coliseu/collection/performances/pequeno_prazer/pequeno_prazer_1.jpg'
import pequeno_prazer_2 from '../assets/images/o_coliseu/collection/performances/pequeno_prazer/pequeno_prazer_2.jpg'
import pequeno_prazer_3 from '../assets/images/o_coliseu/collection/performances/pequeno_prazer/pequeno_prazer_3.jpg'
import pequeno_prazer_4 from '../assets/images/o_coliseu/collection/performances/pequeno_prazer/pequeno_prazer_4.jpg'
import pequeno_prazer_5 from '../assets/images/o_coliseu/collection/performances/pequeno_prazer/pequeno_prazer_5.jpg'
import pequeno_prazer_6 from '../assets/images/o_coliseu/collection/performances/pequeno_prazer/pequeno_prazer_6.jpg'
import pequeno_prazer_7 from '../assets/images/o_coliseu/collection/performances/pequeno_prazer/pequeno_prazer_7.jpg'
import pequeno_prazer_8 from '../assets/images/o_coliseu/collection/performances/pequeno_prazer/pequeno_prazer_8.jpg'
import pequeno_prazer_9 from '../assets/images/o_coliseu/collection/performances/pequeno_prazer/pequeno_prazer_9.jpg'
import contemplar_te_1 from '../assets/images/o_coliseu/collection/performances/contemplar_te/contemplar_te_1.jpg'
import contemplar_te_2 from '../assets/images/o_coliseu/collection/performances/contemplar_te/contemplar_te_2.jpg'
import contemplar_te_3 from '../assets/images/o_coliseu/collection/performances/contemplar_te/contemplar_te_3.jpg'
import contemplar_te_4 from '../assets/images/o_coliseu/collection/performances/contemplar_te/contemplar_te_4.jpg'
import contemplar_te_5 from '../assets/images/o_coliseu/collection/performances/contemplar_te/contemplar_te_5.jpg'
import contemplar_te_6 from '../assets/images/o_coliseu/collection/performances/contemplar_te/contemplar_te_6.jpg'
import contemplar_te_7 from '../assets/images/o_coliseu/collection/performances/contemplar_te/contemplar_te_7.jpg'
import contemplar_te_8 from '../assets/images/o_coliseu/collection/performances/contemplar_te/contemplar_te_8.jpg'
import contemplar_te_9 from '../assets/images/o_coliseu/collection/performances/contemplar_te/contemplar_te_9.jpg'
import paredes_vivas_1 from '../assets/images/o_coliseu/collection/performances/paredes_vivas/paredes_vivas_1.jpg'
import paredes_vivas_2 from '../assets/images/o_coliseu/collection/performances/paredes_vivas/paredes_vivas_2.jpg'
import paredes_vivas_3 from '../assets/images/o_coliseu/collection/performances/paredes_vivas/paredes_vivas_3.jpg'
import paredes_vivas_4 from '../assets/images/o_coliseu/collection/performances/paredes_vivas/paredes_vivas_4.jpg'
import paredes_vivas_5 from '../assets/images/o_coliseu/collection/performances/paredes_vivas/paredes_vivas_5.jpg'
import paredes_vivas_6 from '../assets/images/o_coliseu/collection/performances/paredes_vivas/paredes_vivas_6.jpg'
import paredes_vivas_7 from '../assets/images/o_coliseu/collection/performances/paredes_vivas/paredes_vivas_7.jpg'
import paredes_vivas_8 from '../assets/images/o_coliseu/collection/performances/paredes_vivas/paredes_vivas_8.jpg'
import paredes_vivas_9 from '../assets/images/o_coliseu/collection/performances/paredes_vivas/paredes_vivas_9.jpg'
import musica_desktop_hero from '../assets/images/o_coliseu/collection/musica/hero_desktop.webp'
import musica_mobile_hero from '../assets/images/o_coliseu/collection/musica/hero_mobile.webp'
import musica_thumbnail from '../assets/images/o_coliseu/collection/musica/thumbnail.webp'
import quarteto_coliseu_1 from '../assets/images/o_coliseu/collection/musica/quarteto_coliseu/quarteto_coliseu_1.jpg'
import quarteto_coliseu_2 from '../assets/images/o_coliseu/collection/musica/quarteto_coliseu/quarteto_coliseu_2.jpg'
import quarteto_coliseu_3 from '../assets/images/o_coliseu/collection/musica/quarteto_coliseu/quarteto_coliseu_3.jpg'
import quarteto_coliseu_4 from '../assets/images/o_coliseu/collection/musica/quarteto_coliseu/quarteto_coliseu_4.jpg'
import quarteto_coliseu_5 from '../assets/images/o_coliseu/collection/musica/quarteto_coliseu/quarteto_coliseu_5.jpg'
import quarteto_coliseu_6 from '../assets/images/o_coliseu/collection/musica/quarteto_coliseu/quarteto_coliseu_6.jpg'
import promo_bandas_sonoras from "../assets/images/o_coliseu/collection/musica/promo_bandas_sonoras.webp"
import promo_musica_criancas from "../assets/images/o_coliseu/collection/musica/promo_musica_criancas.webp"
import promo_musica_erudita from "../assets/images/o_coliseu/collection/musica/promo_musica_erudita.webp"
import promo_pop_rock from "../assets/images/o_coliseu/collection/musica/promo_pop_rock.webp"
import orquestra_salao_1 from '../assets/images/o_coliseu/collection/musica/orquestra_salao/orquestra_salao_1.jpg'
import orquestra_salao_2 from '../assets/images/o_coliseu/collection/musica/orquestra_salao/orquestra_salao_2.jpg'
import orquestra_salao_3 from '../assets/images/o_coliseu/collection/musica/orquestra_salao/orquestra_salao_3.jpg'
import orquestra_salao_4 from '../assets/images/o_coliseu/collection/musica/orquestra_salao/orquestra_salao_4.jpg'
import orquestra_salao_5 from '../assets/images/o_coliseu/collection/musica/orquestra_salao/orquestra_salao_5.jpg'
import orquestra_salao_6 from '../assets/images/o_coliseu/collection/musica/orquestra_salao/orquestra_salao_6.jpg'
import orquestra_salao_7 from '../assets/images/o_coliseu/collection/musica/orquestra_salao/orquestra_salao_7.jpg'
import orquestra_salao_8 from '../assets/images/o_coliseu/collection/musica/orquestra_salao/orquestra_salao_8.jpg'
import orquestra_salao_9 from '../assets/images/o_coliseu/collection/musica/orquestra_salao/orquestra_salao_9.jpg'
import arte_urbana_desktop_hero from '../assets/images/o_coliseu/collection/arte_urbana/hero_desktop.webp'
import arte_urbana_mobile_hero from '../assets/images/o_coliseu/collection/arte_urbana/hero_mobile.webp'
import arte_urbana_thumbnail from '../assets/images/o_coliseu/collection/arte_urbana/thumbnail.webp'
import graffiti_virtual_1 from '../assets/images/o_coliseu/collection/arte_urbana/graffiti_virtual/graffiti_virtual_1.jpg'
import graffiti_virtual_2 from '../assets/images/o_coliseu/collection/arte_urbana/graffiti_virtual/graffiti_virtual_2.jpg'
import dj_set_1 from '../assets/images/o_coliseu/collection/arte_urbana/dj_set/dj_set_1.jpg'
import dj_set_2 from '../assets/images/o_coliseu/collection/arte_urbana/dj_set/dj_set_2.jpg'

const collectionCategories = [
    {
        id: 1,
        name: "Performances",
        slug: "/o-coliseu/collection/performances",
        description: "O Teatro e a Dança permitem criar um momento artístico que transcende a simplicidade do dia-a-dia, elevando-o a uma experiência memorável e singular. Seja a meio de uma refeição, num evento corporativo ou num momento solene, as nossas performances foram concebidas para se adequarem à atmosfera e ao estilo do seu evento, provocando e irrompendo do quotidiano. Sempre com o toque singular que só a arte pode dar a cada momento. Consulte-nos para conhecer detalhadamente as nossas performances e adequá-las ao seu evento.",
        hero: {
            xs: performances_mobile_hero,
            gtXs: performances_desktop_hero,
        },
        thumbnail: performances_thumbnail,
        action: {
            name: "Descarregar PDF",
            action: "download",
            url: "https://api.coliseu.pt/static/media/2024/07/30/cb43414e-0d43-41fe-98d9-d630dacb9c9a.pdf"
        },
        offerings: [
            {
                id: 1,
                name: "Eu Verdadeiro",
                area: "Teatro",
                description: "E se eu for mais do que aquilo que os outros veem de mim? Nesta performance, várias personagens, perdidas entre a multidão, passam despercebidas... Até surpreenderem e decidirem revelar-se, partilhando um pouco de si e da verdade por detrás da máscara quotidiana.",
                cover: eu_verdadeiro_1,
                recomendations: "Recomendações por definir",
                metrics: [
                    { name: "Ocupa Espaço", value: 85 },
                    { name: "Interação Base", value: 45 },
                    { name: "Visualmente Forte", value: 85 }
                ],
                photos: [
                    eu_verdadeiro_1,
                    eu_verdadeiro_2,
                    eu_verdadeiro_3,
                    eu_verdadeiro_4,
                    eu_verdadeiro_5,
                    eu_verdadeiro_6,
                    eu_verdadeiro_7,
                    eu_verdadeiro_8,
                    eu_verdadeiro_9,
                ],
                actions: [
                    { action: "click", name: "Saber Mais" }
                ]

            },
            {
                id: 2,
                name: "Pequeno Prazer",
                area: "Teatro",
                description: "Um casal transforma uma simples mesa de restaurante num palco de memórias e emoções. O passado é exposto como um quadro vivo diante de todos, num diálogo físico com poucas palavras, que nos fala através dos sentidos.",
                cover: pequeno_prazer_1,
                recomendations: "Recomendações por definir",
                metrics: [
                    { name: "Espaço Base", value: 45 },
                    { name: "Pouca Interação", value: 15 },
                    { name: "Visual Base", value: 45 }
                ],
                photos: [
                    pequeno_prazer_1,
                    pequeno_prazer_2,
                    pequeno_prazer_3,
                    pequeno_prazer_4,
                    pequeno_prazer_5,
                    pequeno_prazer_6,
                    pequeno_prazer_7,
                    pequeno_prazer_8,
                    pequeno_prazer_9,
                ],
                actions: [
                    { action: "click", name: "Saber Mais" }
                ]

            },
            {
                id: 3,
                name: "Contemplar (-te)",
                area: "Dança",
                description: "Num mundo onde já não paramos para contemplar, um corpo solitário numa mesa aguarda ser servido de poesia e imaginação, revelando-se aos poucos através de objetos que desvendam novas dimensões de sentir e criar. E de uma nova cor onde antes só havia branco.",
                cover: contemplar_te_1,
                recomendations: "Recomendações por definir",
                metrics: [
                    { name: "Ocupa Espaço", value: 75 },
                    { name: "Interação Base", value: 45 },
                    { name: "Visualmente Forte", value: 65 }
                ],
                photos: [
                    contemplar_te_1,
                    contemplar_te_2,
                    contemplar_te_3,
                    contemplar_te_4,
                    contemplar_te_5,
                    contemplar_te_6,
                    contemplar_te_7,
                    contemplar_te_8,
                    contemplar_te_9,
                ],
                actions: [
                    { action: "click", name: "Saber Mais" }
                ]

            },
            {
                id: 4,
                name: "Paredes Vidas",
                area: "Dança",
                description: "Uma simples parede transforma- se em vida, no palco de um vaivém de corpos dançantes, tornando - se habitada de imagens, de muitas histórias, trajetórias e relações.Uma performance feita de movimento, horizontalidade e verticalidade e da fusão dos corpos.",
                cover: paredes_vivas_1,
                recomendations: "Recomendações por definir",
                metrics: [
                    { name: "Ocupa Espaço", value: 75 },
                    { name: "Interação Base", value: 40 },
                    { name: "Visualmente Forte", value: 75 },
                ],
                photos: [
                    paredes_vivas_1,
                    paredes_vivas_2,
                    paredes_vivas_3,
                    paredes_vivas_4,
                    paredes_vivas_5,
                    paredes_vivas_6,
                    paredes_vivas_7,
                    paredes_vivas_8,
                    paredes_vivas_9,
                ],
                actions: [
                    { action: "click", name: "Saber Mais" }
                ]
            }

        ]
    },
    {
        id: 2,
        name: "Música",
        slug: "/o-coliseu/collection/musica",
        description: "A música tem o poder de transformar momentos em memórias inesquecíveis.Seja em formato quarteto ou ensemble de nove músicos(dez, com piano), seja com uma verdadeira orquestra de nove elementos, oferecemos soluções à medida de cada evento.",
        hero: {
            xs: musica_mobile_hero,
            gtXs: musica_desktop_hero,
        },

        thumbnail: musica_thumbnail,
        action: {
            name: "Descarregar PDF",
            action: "download",
            url: "https://api.coliseu.pt/static/media/2024/07/30/cb43414e-0d43-41fe-98d9-d630dacb9c9a.pdf"
        },
        offerings: [
            {
                id: 1,
                name: "Quarteto ou Ensemble Coliseu",
                description: "A elegância de uma peça clássica, a nostalgia de algumas das bandas sonoras dos nossos filmes favoritos, a alegria contagiante das canções infantis, ou a energia que só um hit pop/ rock nos provoca. Disponibilizamos quatro ofertas musicais temáticas para serem tocadas ao vivo por músicos profissionais de grande talento. Escolha um tema, selecione as canções ao seu gosto e à altura do seu evento, e divirta-se.",
                moreInformation: {
                },
                photos: [
                    quarteto_coliseu_1,
                    quarteto_coliseu_2,
                    quarteto_coliseu_3,
                    quarteto_coliseu_4,
                    quarteto_coliseu_5,
                    quarteto_coliseu_6
                ],
                actions: [
                    { action: "expand", name: "Contacto", more: "Para nos contactar e obter mais informações por favor utilizar o email collection@coliseu.pt" }
                ],
                promos: [
                    promo_musica_erudita,
                    promo_bandas_sonoras,
                    promo_musica_criancas,
                    promo_pop_rock
                ]
            },
            {
                id: 2,
                name: "Orquestra Salão Jardim Passos Manuel",
                description: "E se pudesse ter uma orquestra histórica só para si? A Orquestra Salão Jardim, projeto que faz parte da génese do Coliseu, também atua em momentos selecionados. Nos últimos anos, trabalhámos na organização, digitalização e tratamento arquivístico do espólio de cerca de duas mil partituras que constituem património histórico. A Orquestra Salão Jardim pode atuar com o seu repertório próprio, fazendo dançar todos os presentes em qualquer evento, tal como fazia há 100 anos no Salão Jardim Passos Manuel, o icónico espaço que antecedeu o Coliseu.",
                moreInformation: {
                },
                photos: [
                    orquestra_salao_1,
                    orquestra_salao_2,
                    orquestra_salao_3,
                    orquestra_salao_4,
                    orquestra_salao_5,
                    orquestra_salao_6,
                    orquestra_salao_7,
                    orquestra_salao_8,
                    orquestra_salao_9,
                ],
                actions: [
                    { action: "expand", name: "Contacto", more: "Para nos contactar e obter mais informações por favor utilizar o email collection@coliseu.pt" }
                ]
            }
        ]
    },
    {
        id: 3,
        name: "Arte Urbana",
        slug: "/o-coliseu/collection/arte-urbana",
        description: "Expressão vibrante e disruptiva da realidade, a arte urbana proporciona experiências diferenciadas. A rua e os clubes estão aqui ao lado e fazem parte do imaginário urbano que apresentamos de forma diferenciada e única, desde o Graffiti, à realidade virtual, a os momentos de Breaking ou a um DJ Set com ou sem Beatbox.",
        hero: {
            xs: arte_urbana_mobile_hero,
            gtXs: arte_urbana_desktop_hero
        },
        thumbnail: arte_urbana_thumbnail,
        action: {
            name: "Descarregar PDF",
            action: "download",
            url: "https://api.coliseu.pt/static/media/2024/07/30/cb43414e-0d43-41fe-98d9-d630dacb9c9a.pdf"
        },
        offerings: [
            {
                id: 1,
                name: "Graffiti Virtual",
                description: "O Coliseu une-se ao conceituado artista português Mesk para proporcionar experiências únicas com Graffiti, 3D e Realidade Virtual. Quer seja através das tintas, quer seja através das novas tecnologias, estas experiências permitem dar asas à imaginação e à arte. Os resultados finais serão impressionantes e surpreendentes.",
                moreInformation: {
                },
                photos: [
                    graffiti_virtual_1,
                    graffiti_virtual_2
                ],
                actions: [
                    { action: "expand", name: "Contacto", more: "Para nos contactar e obter mais informações por favor utilizar o email collection@coliseu.pt" }
                ]
            },
            {
                id: 2,
                name: "DJ Set / Beatbox / Breaking",
                description: "Um DJ Set pode ser parte fundamental de todo o ambiente que necessita no seu evento.Com Beatbox, a animação é garantida.Ninguém ficará indiferente ao DJ Set do Coliseu.O Breaking que nasceu como manifestação popular e alternativa entre os jovens, hoje é promovida a modalidade olímpica.O Breaking torna qualquer evento mais urbano, sendo uma das formas de arte favoritas dos mais novos.Contamos com o talento de B- Boys e B - Girls para levar parte da cultura hip - hop ao seu evento.",
                moreInformation: {
                },
                photos: [
                    dj_set_1,
                    dj_set_2
                ],
                actions: [
                    { action: "expand", name: "Contacto", more: "Para nos contactar e obter mais informações por favor utilizar o email collection@coliseu.pt" }
                ]
            },
        ]
    }
]

export default collectionCategories
