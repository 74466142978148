import sala_principal_name_image from '../assets/images/o_coliseu/espacos/sala_principal/name.svg';
import sala_principal_desktop_hero from '../assets/images/o_coliseu/espacos/sala_principal/hero_desktop.webp';
import sala_principal_mobile_hero from '../assets/images/o_coliseu/espacos/sala_principal/hero_mobile.webp';
import sala_principal_thumbnail from '../assets/images/o_coliseu/espacos/sala_principal/thumbnail.webp';
import sala_principal_top_infographic from '../assets/images/o_coliseu/espacos/sala_principal/top_infographic.webp';
import sala_principal_bottom_infographic from '../assets/images/o_coliseu/espacos/sala_principal/bottom_infographic.webp';
import sala_principal_1 from '../assets/images/o_coliseu/espacos/sala_principal/sala_principal_1.jpg'
import sala_principal_2 from '../assets/images/o_coliseu/espacos/sala_principal/sala_principal_2.jpg'
import sala_principal_3 from '../assets/images/o_coliseu/espacos/sala_principal/sala_principal_3.jpg'
import sala_principal_4 from '../assets/images/o_coliseu/espacos/sala_principal/sala_principal_4.jpg'
import sala_principal_5 from '../assets/images/o_coliseu/espacos/sala_principal/sala_principal_5.jpg'
import sala_principal_6 from '../assets/images/o_coliseu/espacos/sala_principal/sala_principal_6.jpg'
import sala_principal_7 from '../assets/images/o_coliseu/espacos/sala_principal/sala_principal_7.jpg'

import salao_atico_name_image from '../assets/images/o_coliseu/espacos/salao_atico/name.svg';
import salao_atico_desktop_hero from '../assets/images/o_coliseu/espacos/salao_atico/hero_desktop.webp';
import salao_atico_mobile_hero from '../assets/images/o_coliseu/espacos/salao_atico/hero_mobile.webp';
import salao_atico_thumbnail from '../assets/images/o_coliseu/espacos/salao_atico/thumbnail.webp';
import salao_atico_top_infographic from '../assets/images/o_coliseu/espacos/salao_atico/top_infographic.webp';
import salao_atico_bottom_infographic from '../assets/images/o_coliseu/espacos/salao_atico/bottom_infographic.webp';
import salao_atico_1 from '../assets/images/o_coliseu/espacos/salao_atico/salao_atico_1.jpg'
import salao_atico_2 from '../assets/images/o_coliseu/espacos/salao_atico/salao_atico_2.jpg'
import salao_atico_3 from '../assets/images/o_coliseu/espacos/salao_atico/salao_atico_3.jpg'
import salao_atico_4 from '../assets/images/o_coliseu/espacos/salao_atico/salao_atico_4.jpg'
import salao_atico_5 from '../assets/images/o_coliseu/espacos/salao_atico/salao_atico_5.jpg'
import salao_atico_6 from '../assets/images/o_coliseu/espacos/salao_atico/salao_atico_6.jpg'
import salao_atico_7 from '../assets/images/o_coliseu/espacos/salao_atico/salao_atico_7.jpg'

import salao_jardim_name_image from '../assets/images/o_coliseu/espacos/salao_jardim/name.svg';
import salao_jardim_desktop_hero from '../assets/images/o_coliseu/espacos/salao_jardim/hero_desktop.webp';
import salao_jardim_mobile_hero from '../assets/images/o_coliseu/espacos/salao_jardim/hero_mobile.webp';
import salao_jardim_thumbnail from '../assets/images/o_coliseu/espacos/salao_jardim/thumbnail.webp';
import salao_jardim_top_infographic from '../assets/images/o_coliseu/espacos/salao_jardim/top_infographic.webp';
import salao_jardim_bottom_infographic from '../assets/images/o_coliseu/espacos/salao_jardim/bottom_infographic.webp';
import salao_jardim_1 from '../assets/images/o_coliseu/espacos/salao_jardim/salao_jardim_1.jpg'
import salao_jardim_2 from '../assets/images/o_coliseu/espacos/salao_jardim/salao_jardim_2.jpg'
import salao_jardim_3 from '../assets/images/o_coliseu/espacos/salao_jardim/salao_jardim_3.jpg'
import salao_jardim_4 from '../assets/images/o_coliseu/espacos/salao_jardim/salao_jardim_4.jpg'
import salao_jardim_5 from '../assets/images/o_coliseu/espacos/salao_jardim/salao_jardim_5.jpg'
import salao_jardim_6 from '../assets/images/o_coliseu/espacos/salao_jardim/salao_jardim_6.jpg'
import salao_jardim_7 from '../assets/images/o_coliseu/espacos/salao_jardim/salao_jardim_7.jpg'

import lounge_ageas_name_image from '../assets/images/o_coliseu/espacos/lounge_ageas/name.svg';
import lounge_ageas_desktop_hero from '../assets/images/o_coliseu/espacos/lounge_ageas/hero_desktop.webp';
import lounge_ageas_mobile_hero from '../assets/images/o_coliseu/espacos/lounge_ageas/hero_mobile.webp';
import lounge_ageas_thumbnail from '../assets/images/o_coliseu/espacos/lounge_ageas/thumbnail.webp';
import lounge_ageas_top_infographic from '../assets/images/o_coliseu/espacos/lounge_ageas/top_infographic.webp';
import lounge_ageas_1 from '../assets/images/o_coliseu/espacos/lounge_ageas/lounge_ageas_1.jpg'
import lounge_ageas_2 from '../assets/images/o_coliseu/espacos/lounge_ageas/lounge_ageas_2.jpg'
import lounge_ageas_3 from '../assets/images/o_coliseu/espacos/lounge_ageas/lounge_ageas_3.jpg'
import lounge_ageas_4 from '../assets/images/o_coliseu/espacos/lounge_ageas/lounge_ageas_4.jpg'


import foyer_name_image from '../assets/images/o_coliseu/espacos/foyer/name.svg';
import foyer_desktop_hero from '../assets/images/o_coliseu/espacos/foyer/hero_desktop.webp';
import foyer_mobile_hero from '../assets/images/o_coliseu/espacos/foyer/hero_mobile.webp';
import foyer_thumbnail from '../assets/images/o_coliseu/espacos/foyer/thumbnail.webp';
import foyer_top_infographic from '../assets/images/o_coliseu/espacos/foyer/top_infographic.webp';
import foyer_1 from '../assets/images/o_coliseu/espacos/foyer/foyer_1.jpg'
import foyer_2 from '../assets/images/o_coliseu/espacos/foyer/foyer_2.jpg'
import foyer_3 from '../assets/images/o_coliseu/espacos/foyer/foyer_3.jpg'
import foyer_4 from '../assets/images/o_coliseu/espacos/foyer/foyer_4.jpg'


const spaces = [
    {
        id: 1,
        name: "Sala Principal",
        nameImage: sala_principal_name_image,
        slug: "/o-coliseu/espacos/sala-principal",
        description: "Com uma capacidade máxima cerca de 3.000 lugares sentados e 4.000 em pé, a Sala Principal do Coliseu Porto Ageas tem possibilidades infinitas e adapta-se a diferentes lotações.A decoração de estilo clássico coabita com os equipamentos e tecnologias mais recentes, criando ao mesmo tempo um ambiente único e totalmente preparado para receber os melhores espetáculos mundiais. Por aqui já passaram nomes como Montserrat Caballé, Miles Davis, Bob Dylan, Mark Knopfler, Amália Rodrigues, Cesária Évora, Margot Fonteyn, Ray Charles, Philip Glass, Ryuichi Sakamoto, B.B.King, Maria Callas ou Radiohead. Dispõe ainda de uma pista de circo com características únicas no país e dois fossos de orquestra para grandes óperas. Esta sala, pela sua versatilidade, está também disponível para acolher congressos e eventos corporativos de múltipla natureza.",
        observations: "* possibilidade de serviço de babysitting no Salão Jardim",
        hero: {
            xs: sala_principal_mobile_hero,
            gtXs: sala_principal_desktop_hero,
        },
        order: 1,
        suggestions: [
            "espetáculos",
            "eventos",
            "conferências",
            "cerimónias",
            "jantares"
        ],
        actions: [
            { action: "download", url: "https://api.coliseu.pt/static/media/2024/07/29/5367d924-4e4f-4236-a799-2753321573bc.pdf", name: "Descarregar PDF" },
            { action: "link", url: "/area-tecnica", name: "Rider Técnico" },
            { action: "expand", url: "/contactos", name: "Contacto", more: "Para nos contactar e obter mais informações por favor utilizar o email espacos@coliseu.pt" }
        ],
        thumbnail: sala_principal_thumbnail,
        topInfographic: sala_principal_top_infographic,
        bottomInfographic: sala_principal_bottom_infographic,
        photos: [
            sala_principal_1,
            sala_principal_2,
            sala_principal_3,
            sala_principal_4,
            sala_principal_5,
            sala_principal_6,
            sala_principal_7,
        ]
    },
    {
        id: 2,
        name: "Salão Ático",
        nameImage: salao_atico_name_image,
        slug: "/o-coliseu/espacos/salao-atico",
        description: "Situa-se no piso superior do Coliseu Porto Ageas e é um espaço vocacionado para acolher eventos de média dimensão. Renovada em 2022, permite uma grande flexibilidade na disposição do mobiliário e na montagem de diversas soluções de iluminação e som. Esta sala está preparada para espetáculos, concertos, recitais, exposições, conferências, sessões de cinema, apresentações e até jantares ou festas.",
        hero: {
            xs: salao_atico_mobile_hero,
            gtXs: salao_atico_desktop_hero
        },
        order: 2,
        suggestions: [
            "espetáculos",
            "eventos",
            "conferências",
            "apresentações",
            "jantares"
        ],
        actions: [
            { action: "download", url: "https://api.coliseu.pt/static/media/2024/07/29/5367d924-4e4f-4236-a799-2753321573bc.pdf", name: "Descarregar PDF" },
            { action: "link", url: "/area-tecnica", name: "Rider Técnico" },
            { action: "expand", url: "/contactos", name: "Contacto", more: "Para nos contactar e obter mais informações por favor utilizar o email espacos@coliseu.pt" }
        ],
        thumbnail: salao_atico_thumbnail,
        topInfographic: salao_atico_top_infographic,
        bottomInfographic: salao_atico_bottom_infographic,
        photos: [
            salao_atico_1,
            salao_atico_2,
            salao_atico_3,
            salao_atico_4,
            salao_atico_5,
            salao_atico_6,
            salao_atico_7,
        ]
    },
    {
        id: 3,
        name: "Salão Jardim",
        nameImage: salao_jardim_name_image,
        slug: "/o-coliseu/espacos/salao-jardim",
        description: "O Salão Jardim situa-se no piso térreo, ao nível da plateia, e é um espaço mais intimista e com acessibilidade. Pode ser utilizado para eventos de menor dimensão, como concertos acústicos, showcases, exposições, conversas, reuniões e apresentações.",
        hero: {
            xs: salao_jardim_mobile_hero,
            gtXs: salao_jardim_desktop_hero
        },
        order: 3,
        suggestions: [
            "concertos acústicos",
            "eventos",
            "conferências",
            "apresentações",
            "reuniões",
            "exposições "
        ],
        actions: [
            { action: "download", url: "https://api.coliseu.pt/static/media/2024/07/29/5367d924-4e4f-4236-a799-2753321573bc.pdf", name: "Descarregar PDF" },
            { action: "link", url: "/area-tecnica", name: "Rider Técnico" },
            { action: "expand", url: "/contactos", name: "Contacto", more: "Para nos contactar e obter mais informações por favor utilizar o email espacos@coliseu.pt" }
        ],
        thumbnail: salao_jardim_thumbnail,
        topInfographic: salao_jardim_top_infographic,
        bottomInfographic: salao_jardim_bottom_infographic,
        photos: [
            salao_jardim_1,
            salao_jardim_2,
            salao_jardim_3,
            salao_jardim_4,
            salao_jardim_5,
            salao_jardim_6,
            salao_jardim_7,
        ]
    },
    {
        id: 4,
        name: "Lounge Ageas",
        nameImage: lounge_ageas_name_image,
        slug: "/o-coliseu/espacos/lounge-ageas",
        description: "O Lounge Ageas situa-se no Piso 1, ao nível dos Camarotes, e é um espaço de menor dimensão, com luz natural e mezanino, habitualmente usado para residências artísticas, exposições, formações e pequenos cocktails em dias de espetáculo e para momentos mais privados de convívio. Dispõe de luz natural, mezanino, pontos de luz, água e internet.",
        hero: {
            xs: lounge_ageas_mobile_hero,
            gtXs: lounge_ageas_desktop_hero,
        },
        order: 4,
        suggestions: [
            "apresentações",
            "reuniões",
            "exposições",
            "cocktails"
        ],
        actions: [
            { action: "download", url: "https://api.coliseu.pt/static/media/2024/07/29/5367d924-4e4f-4236-a799-2753321573bc.pdf", name: "Descarregar PDF" },
            { action: "link", url: "/area-tecnica", name: "Rider Técnico" },
            { action: "expand", url: "/contactos", name: "Contacto", more: "Para nos contactar e obter mais informações por favor utilizar o email espacos@coliseu.pt" }
        ],
        thumbnail: lounge_ageas_thumbnail,
        topInfographic: lounge_ageas_top_infographic,
        photos: [
            lounge_ageas_1,
            lounge_ageas_2,
            lounge_ageas_3,
            lounge_ageas_4,
        ]
    },
    {
        id: 5,
        name: "Foyer",
        nameImage: foyer_name_image,
        slug: "/o-coliseu/espacos/foyer",
        description: "O Foyer ergue-se de nobreza para dar as boas-vindas a quem entra no Coliseu. Entre os quatro baixos-relevos do escultor Henrique Moreira e um dos raros relevos que ainda ostentam o antigo brasão da cidade, encimado pela coroa e pelo dragão, o Foyer é um espaço de apoio a grandes eventos e permite ativações de marca e cocktails, mas também apresentações corporativas.",
        hero: {
            xs: foyer_mobile_hero,
            gtXs: foyer_desktop_hero
        },
        order: 5,
        suggestions: [
            "eventos",
            "apresentações",
            "eventos corporativos",
            "cocktails"
        ],
        actions: [
            { action: "download", url: "https://api.coliseu.pt/static/media/2024/07/29/5367d924-4e4f-4236-a799-2753321573bc.pdf", name: "Descarregar PDF" },
            { action: "link", url: "/area-tecnica", name: "Rider Técnico" },
            { action: "expand", url: "/contactos", name: "Contacto", more: "Para nos contactar e obter mais informações por favor utilizar o email espacos@coliseu.pt" }
        ],
        thumbnail: foyer_thumbnail,
        topInfographic: foyer_top_infographic,
        photos: [
            foyer_1,
            foyer_2,
            foyer_3,
            foyer_4,
        ]
    }
]

export default spaces
