import { Fade } from '@material-ui/core';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { downloadRequest } from '../../services/Utils';
import Show from '../Show/Show';
import Typography from '../Typography/Typography';
import styles from './ActionButton.module.scss';

interface ActionButtonProps {
    name: string
    url: string
    action?: string // "link" | "download"
    fullWidth?: boolean
    downloadFilename?: string
    onClick?: (event: any) => void
    more?: string
}

export default function ActionButton(props: ActionButtonProps) {
    const { name, onClick, action = "link", url, fullWidth = false, downloadFilename = "download.pdf", more = "" } = props
    const [open, setOpen] = useState(false)

    const history = useHistory()
    const classes = clsx({
        [styles.ActionButton]: true,
        [styles.FullWidth]: fullWidth,
        [styles.Expanded]: action === "expand" && open
    })

    const handleClick = (event: any) => {

        if (action === "download") {
            downloadRequest(url, downloadFilename)
        }

        if (action === "link") {
            history?.push(url)
        }

        if (action === "click") {
            if (!onClick) {
                alert("onClick is undefined")
            } else {
                onClick(event)
            }
        }

        if (action === "expand") {
            setOpen(!open)
        }
    }

    return (
        <div>
            <div onClick={handleClick} className={classes}>
                <Typography variant="RocGroteskBold18" className={styles.Name}>
                    {name}
                </Typography>
            </div>
            <Show on={action === "expand" && open}>
                <Fade in={open}>
                    <div className={styles.More}>
                        <Typography variant="RocGroteskRegular16">
                            {more}
                        </Typography>
                    </div>
                </Fade>
            </Show>
        </div>
    )
}
