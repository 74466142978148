import { ApolloProvider } from '@apollo/react-hooks';
import { ThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import styles from './App.module.scss';
import ActivateView from './components/ActivateView/ActivateView';
import CircusView from './components/CircusView/CircusView';
import ColisseumSpacesView from './components/ColisseumSpaces/ColisseumSpaces';
import Consent from './components/Consent/Consent';
import DefaultMetaTags from './components/DefaultMetaTags/DefaultMetaTags';
import DesktopMenu from './components/DesktopMenu/DesktopMenu';
import EducationalActivityEnrollView from './components/EducationalActivityEnrollView/EducationalActivityEnrollView';
import EducationalActivityThanksView from './components/EducationalActivityThanksView/EducationalActivityThanksView';
import EducationalActivityView from './components/EducationalActivityView/EducationalActivityView';
import EducationalCategoryView from './components/EducationalCategoryView/EducationalCategoryView';
import EducationalView from './components/EducationalView/EducationalView';
import EventDetailsView from './components/EventDetailsView/EventDetailsView';
import Footer from './components/Footer/Footer';
import FriendsView from './components/FriendsView/FriendsView';
import Header from './components/Header/Header';
import HomeView from './components/HomeView/HomeView';
import InformationView from './components/InformationView/InformationView';
import InstitutionView from './components/InstitutionView/InstitutionView';
import PolicyView from './components/PolicyView/PolicyView';
import ScheduleView from './components/ScheduleView/ScheduleView';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import SpaceView from './components/SpaceView/SpaceView';
import Status from './components/Status/Status';
import SubscribeView from './components/SubscribeView/SubscribeView';
import TicketOfficeView from './components/TicketOfficeView/TicketOfficeView';
import ToursView from './components/ToursView/ToursView';
import UnsubscribeView from './components/UnsubscribeView/UnsubscribeView';
import client from "./services/Client";
import theme from './theme';
import CollectionView from './components/CollectionView/CollectionView';
import CollectionCategoryView from './components/CollectionCategoryView/CollectionCategoryView';
import P from './components/PageTransition/PageTransition';

const App = () => {
    console.log(styles)

    const host = window.location.host
    const HomeComponent = !host.startsWith("educativo") ? HomeView : EducationalView

    return (
        <ApolloProvider client={client}>
            <Router>
                <ScrollToTop />
                <DefaultMetaTags />
                <ThemeProvider theme={theme}>
                    <Header />
                    <DesktopMenu />
                    <Consent />
                    <Route exact path="/" component={HomeComponent} />
                    <Route exact path="/arquivo" component={ScheduleView} />
                    <Route exact path="/agenda" component={ScheduleView} />
                    <Route exact path="/o-coliseu/espacos" render={() => (<P><ColisseumSpacesView /></P>)} />
                    <Route exact path="/o-coliseu/espacos/sala-principal" render={(p) => (<P><SpaceView {...p} /></P>)} />
                    <Route exact path="/o-coliseu/espacos/salao-atico" render={(p) => (<P> <SpaceView {...p} /></P>)} />
                    <Route exact path="/o-coliseu/espacos/salao-jardim" render={(p) => (<P> <SpaceView {...p} /></P>)} />
                    <Route exact path="/o-coliseu/espacos/lounge-ageas" render={(p) => (<P> <SpaceView {...p} /></P>)} />
                    <Route exact path="/o-coliseu/espacos/foyer" render={(p) => (<P> <SpaceView {...p} /></P>)} />
                    <Route exact path="/o-coliseu/collection" render={() => (<P><CollectionView /></P>)} />
                    <Route exact path="/o-coliseu/collection/performances" render={(p) => (<P><CollectionCategoryView {...p} /></P>)} />
                    <Route exact path="/o-coliseu/collection/musica" render={(p) => (<P><CollectionCategoryView {...p} /></P>)} />
                    <Route exact path="/o-coliseu/collection/arte-urbana" render={(p) => (<P><CollectionCategoryView {...p} /></P>)} />
                    <Route exact path="/visitas" component={ToursView} />
                    <Route exact path="/informacoes-e-reservas" component={TicketOfficeView} />
                    <Route exact path="/como-chegar" component={TicketOfficeView} />
                    <Route exact path="/apresentacao" component={InstitutionView} />
                    <Route exact path="/edificio" component={InstitutionView} />
                    <Route exact path="/espacos" component={InstitutionView} />
                    <Route exact path="/historia" component={InstitutionView} />
                    <Route exact path="/apoios" component={InstitutionView} />
                    <Route exact path="/balleteatro" component={InstitutionView} />
                    <Route exact path="/premio" component={InstitutionView} />
                    <Route exact path="/residencias" component={InstitutionView} />
                    <Route exact path="/educativo" component={EducationalView} />
                    <Route exact path="/educativo/programa-escolas/atividade/:slug" component={EducationalActivityView} />
                    <Route exact path="/educativo/programa-escolas/inscrever/:slug" component={EducationalActivityEnrollView} />
                    <Route exact path="/educativo/programa-escolas/submissao/:slug" component={EducationalActivityThanksView} />
                    <Route exact path="/educativo/programa-escolas" component={EducationalView} />
                    <Route exact path="/educativo/elo" component={EducationalView} />
                    <Route exact path="/educativo/informacoes" component={EducationalView} />
                    <Route exact path="/educativo/programa-escolas/categoria/:slug" component={EducationalCategoryView} />
                    <Route exact path="/educativo/atelier-pintura" component={EducationalView} />
                    <Route exact path="/arquivo-noticias" render={(p) => (<P> <InformationView {...p} /></P>)} />
                    <Route exact path="/noticia/:slug" render={(p) => (<P> <InformationView {...p} /></P>)} />
                    <Route exact path="/normas-sala" render={(p) => (<P> <InformationView {...p} /></P>)} />
                    <Route exact path="/area-tecnica" render={(p) => (<P> <InformationView {...p} /></P>)} />
                    <Route exact path="/relatorios" render={(p) => (<P> <InformationView {...p} /></P>)} />
                    <Route exact path="/contactos" render={(p) => (<P> <InformationView {...p} /></P>)} />
                    <Route exact path="/orgaos-sociais" render={(p) => (<P> <InformationView {...p} /></P>)} />
                    <Route exact path="/circo-2015" component={CircusView} />
                    <Route exact path="/circo-2016" component={CircusView} />
                    <Route exact path="/circo-2017" component={CircusView} />
                    <Route exact path="/circo-2018" component={CircusView} />
                    <Route exact path="/circo-2019" component={CircusView} />
                    <Route exact path="/circo-2020" component={CircusView} />
                    <Route exact path="/circo-2021" component={CircusView} />
                    <Route exact path="/circo-2022" component={CircusView} />
                    <Route exact path="/circo-2023" component={CircusView} />
                    <Route exact path="/evento/:slug" component={EventDetailsView} />
                    <Route exact path="/politica-de-privacidade" component={PolicyView} />
                    <Route exact path="/cookies" component={PolicyView} />
                    <Route exact path="/direito-a-imagem" component={PolicyView} />
                    <Route exact path="/status" component={Status} />
                    <Route exact path="/estatutos" component={FriendsView} />
                    <Route exact path="/newsletter/ativar/:list/:token" component={ActivateView} />
                    <Route exact path="/newsletter/subscrever/" component={SubscribeView} />
                    <Route exact path="/newsletter/educativo/" component={SubscribeView} />
                    <Route exact path="/newsletter/remover/" component={UnsubscribeView} />
                    <Footer />
                </ThemeProvider>
            </Router>
        </ApolloProvider>
    );
};

export default App;
