import { Grid } from '@material-ui/core';
import { Maybe } from 'graphql/jsutils/Maybe';
import React, { useState } from 'react';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/dist/styles.css";
import Show from '../Show/Show';
import styles from './PhotoGallery.module.scss';

function limitarTamanhoArray<T>(array: T[], n: number): T[] {
    return array.slice(0, Math.min(array.length, n));
}

function getSlides(photos: Maybe<string[]>) {
    return photos?.map(photo => {
        return { src: photo }
    })
}

interface PhotoGalleryProps {
    photos: Maybe<string[]>
    limit?: number
}

export default function PhotoGallery(props: PhotoGalleryProps) {

    const { photos, limit = 4 } = props
    const [startIndex, setStartIndex] = useState<number>(0)

    const [open, setOpen] = useState<boolean>(false)

    if (!photos?.length) return null

    const display = limitarTamanhoArray(photos, limit)
    const slides = getSlides(photos)
    const more = photos.length - limit

    const xs = limit % 2 === 0 ? 6 : 12

    const handleClick = (index: number) => {
        setStartIndex(index)
        setOpen(true)
    }

    return (
        <div>
            <Grid container className={styles.PhotoGallery} wrap="wrap" spacing={1}>
                {display?.map((_: any, index: number) =>
                    <Grid key={index} item xs={xs} className={styles.Tile} onClick={() => handleClick(index)}>
                        <Show on={display.length - 1 === index && more > 0} >
                            <div className={styles.More}>
                                <span>+{more}</span>
                            </div>
                        </Show>
                        <img src={photos[index]} alt="" />
                    </Grid>
                )}
            </Grid >
            <Lightbox
                open={open}
                close={() => setOpen(false)}
                index={startIndex}
                slides={slides}
                carousel={{
                    finite: true
                }}
                className={styles.LightBox}
            />
        </div>
    )
}

