import { Grid, Slider } from "@material-ui/core"
import React from 'react'
import Show from "../Show/Show"
import Typography from "../Typography/Typography"
import styles from './OfferingDetails.module.scss'

interface OfferingDetailsProps {
    offering: any
}

export default function OfferingDetails(props: OfferingDetailsProps) {

    const { offering: { name, area, description, recomendations, cover, metrics } } = props

    return (
        <Grid container spacing={2} className={styles.Wrapper}>
            <Grid item xs={4}>
                <div className={styles.Name}>
                    <Typography variant="RocGroteskBold30">
                        {name}
                    </Typography>
                </div>
                <div className={styles.Area}>
                    <Typography variant="RocGroteskRegular20">
                        {area}
                    </Typography>
                </div>
                <div className={styles.Description}>
                    <Typography variant="body1">
                        {description}
                    </Typography>
                </div>
                <Show on={recomendations}>
                    <div className={styles.Recomendations}>
                        <Typography variant="subtitle2">
                            Recomendações
                        </Typography>
                        <Typography variant="body1">
                            {recomendations}
                        </Typography>
                    </div>
                </Show>
                <div>
                    <div className={styles.Contacts}>
                        <Typography variant="subtitle2">
                            Contactos
                        </Typography>
                        <Typography variant="body1">
                            (+351) 223 394 940
                        </Typography>
                        <Typography variant="body1">
                            <a href="mailto:eventos@coliseu.pt">eventos@coliseu.pt</a>
                        </Typography>
                    </div>
                </div>
            </Grid>
            <Grid item xs={8}>
                <div className={styles.Cover}>
                    <img src={cover} alt="" />
                </div>

                <Grid container className={styles.Metrics}>
                    {metrics?.map((metric: any, index: number) => {
                        return (
                            <>
                                <Grid item xs={3} className={styles.Name}>
                                    {metric.name}
                                </Grid>
                                <Grid item xs={9}>
                                    <Slider
                                        defaultValue={metric.value}
                                        aria-label="Small"
                                        valueLabelDisplay="auto"
                                        color="primary"
                                        disabled={true}
                                        classes={{
                                            thumb: styles.Thumb
                                        }}
                                    />
                                </Grid>
                            </>
                        )
                    })}
                </Grid>
            </Grid>
        </Grid >
    )
}
