import collectionCategories from '../data/collection-categories'

interface UseCollectionCategoriesParams {
    filter: any
}

export default function useCollectionCategories(params?: UseCollectionCategoriesParams) {
    return {
        collectionCategories,
        loading: false,
        error: null // ou undeined?
    }

}
