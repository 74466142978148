import spaces from '../data/spaces'

interface UseSpacesParams {
    filter: any
}

export default function useSpaces(params?: UseSpacesParams) {
    return {
        spaces,
        loading: false,
        error: null // ou undeined?
    }

}
