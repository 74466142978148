import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/dist/styles.css";
import { Maybe } from '../../types/types';
import styles from './NewGallery.module.scss';

function getSlides(photos: Maybe<string[]>) {
    return photos?.map(photo => {
        return { src: photo }
    })
}

interface NewGalleryProps {
    photos: Maybe<string[]>
}

export default function NewGallery(props: NewGalleryProps) {

    const { photos } = props
    const slides = getSlides(photos)

    const [open, setOpen] = useState<boolean>(false)
    const [startIndex, setStartIndex] = useState<number>(0)

    const handleClick = (index: number) => {
        setStartIndex(index)
        setOpen(true)
    }

    return (
        <div>
            <Carousel showThumbs={false} showArrows={false} swipeable autoPlay infiniteLoop showStatus={false}>
                {photos?.map((photo: any, index: number) => (
                    <div key={index} onClick={() => handleClick(index)} className={styles.Slide}>
                        <img src={photo} alt="" />
                    </div>
                ))}
            </Carousel>
            <Lightbox
                open={open}
                close={() => setOpen(false)}
                index={startIndex}
                slides={slides}
                carousel={{
                    finite: true
                }}
                className={styles.LightBox}
            />

        </div>
    )
}


